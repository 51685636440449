export default {
    open: 'Ouvrir',
    close: 'Proche',
    apply: 'Appliquer',
    cancel: 'Annuler',
    search: 'Chercher',
    cancel_search: 'Annuler la recherche',
    edit_search: 'Modifier la recherche',
    erase: 'Effacer',
    back_to_results: 'Retour aux résultats',
    save: 'Sauver',
    retry: 'Réessayer',
    click_here: 'Cliquez ici',
    copy: 'Copie',
    copied: 'Copié',
    copied_coordinates: 'Coordonnées copiées',
    select: 'Sélectionner',
    selected: 'Sélectionné',
    selected_plural: 'Sélectionnés',
    deselect: 'Désélectionner',
    select_multi_vehicles: 'Sélectionnez un ou plusieurs véhicules',
    include: 'Inclure',
    exclude: 'Exclure',
    balanced: 'Équilibré',
    input: 'Entrée',
    output: 'Sortie',
    verified: 'Vérifié',
    empty_search: 'Aucun résultat trouvé',
    read_more: 'Lire la suite',
    details: 'Détails',
    actual: 'En cours',
    residual_time: 'Temps restant',
    next: 'Prochain',
    previous: 'Précédent',
    menu_open: 'Ouvrir le menu',
    hide: 'Cacher',
    show: 'Montrer',
    none: 'Aucun',
    none_alt: 'Aucune',
    all: 'Tous',
    all_alt: 'Toutes',
    default: 'Défaut',
    settings: 'Paramètres',
    reset_settings: 'Réinitialiser les paramètres',
    reset_settings_success: 'Paramètres réinitialisés avec succès',
    multiselect_default_value: {
        singular: 'Valeur sélectionnée:',
        plural: 'Valeurs sélectionnées:'
    },
    available: 'Disponible',
    unavailable: 'Pas disponible',
    not_available: 'Pas disponible',
    not_available_plural: 'Pas disponibles',
    from: 'de',
    to: 'au',
    of: 'de',
    yes: 'Oui',
    no: 'Non',
    minutes: 'Minutes',
    hours: 'Heures',
    hour: 'Heure',
    days: 'Jours',
    day: 'Jour',
    months: 'Mois',
    month: 'Mois',
    years: 'Ans',
    year: 'An',
    days_names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    days_names_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    months_names: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ],
    months_names_short: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc'
    ],
    areas: 'Zones',
    area: 'Zone',
    time: 'Temps (min)',
    time_label: 'Temps',
    cost: 'Coût',
    toll_cost: 'Coût du péage',
    km_cost: 'Coût à km',
    hourly_cost: 'Coût horaire',
    working_cost: 'Coût par homme total',
    total_cost: 'Coût total',
    expire_date: "Date d'expiration",
    start_date: 'Date de début',
    recap: 'Résumé',
    start: 'Début',
    starting_point: 'Départ',
    end: 'Fin',
    finish_point: 'Arrivée',
    duration: 'Durée',
    distance: 'Distance',
    daily_distance: 'Km parcourus par jour',
    distance_total: 'Distance totale',
    gps_distance: 'Distance (GPS)',
    distance_gps: 'Km parcourus par jour (GPS)',
    pause: 'Pause',
    segment: 'Trajet',
    start_time: 'Heure de début',
    end_time: 'Heure de fin',
    service_time: 'Temps de service',
    waypoint_load: 'Charge à bord',
    state: 'État',
    violated_plafond: 'Violation de Plafond',
    traffic_delay: 'Retard de circulation',
    company: 'Compagnie',
    address: 'Adresse',
    position: 'Localisation',
    route: 'Itinéraire',
    filter_date: 'Filtrer par date',
    warning: 'Prudence',
    no_warning: 'Sans préavis',
    alerts: 'Avis',
    trucks_with_alerts: 'Véhicules avec avertissements',
    bluetooth_devices: 'Appareils Bluetooth',
    no_bluetooth_devices: 'Aucun appareil Bluetooth trouvé',
    connected: 'Connecté',
    disconnected: 'Déconnecté',
    bluetooth_types: {
        temperature_probe: 'Sonde de température',
        door_probe: 'Porte et sonde de température'
    },
    back_to_home: "Retour à l'accueil",
    cerca: 'Chercher',
    search_vehicle: 'Chercher véhicule',
    filter: 'Filtrer',
    filter_label: 'Filtre',
    filters_label: 'Filtres',
    filter_by: 'Filtrer par',
    operations: 'Opérations',
    filter_vehicles: 'Filtrer les véhicules',
    filter_applied: 'Filtre appliqué',
    filter_canceled: 'Filtre annulé avec succès',
    order_by: 'Trier par',
    order: 'Ordre',
    ascending: 'Croissance',
    descending: 'Décroissant',
    value: 'Valeur',
    select_filter: 'Sélectionner un filtre',
    sort_vehicles: 'Trier les véhicules',
    sort_for_alphanumeric: 'Alphabétique',
    sort_for_lastpoint: 'Chronologique',
    sort_for_company: 'Compagnie',
    sort_for_activity: 'Activités',
    company_select: 'Sélectionner entreprise',
    no_companies_founded: 'Aucune entreprise trouvée',
    multicompany: 'Filtre par entreprise',
    no_vehicles_founded: 'Aucun véhicule trouvé',
    print: 'Imprimer',
    export: 'Exporter',
    print_recap: 'Imprimer résumé',
    select_date: 'Sélectionnez une date',
    add: 'Ajouter',
    add_new: 'Ajouter un nouveau',
    delete: 'Supprimer',
    edit: 'Modifier',
    options: 'Options',
    login: 'Veuillez vous connecter',
    logout: 'Déconnexion',
    logout_confirm: 'Êtes-vous sûr de vouloir vous déconnecter?',
    login_button: 'Connexion',
    connect: 'Connexion',
    logged: 'Connecté',
    manage: 'Gérer',
    loading: 'Chargement',
    loading_timeout: 'Erreur de réseau, veuillez recharger la page ou réessayer plus tard',
    go_back: 'Retour',
    select_coordinates: 'Sélectionner les coordonnées',
    other: 'Autre',
    options_general: 'Générales',
    options_language: 'Langue',
    change_interface_language: "Changer la langue de l'interface",
    change_infringment_language: 'Changer la langue des infractions',
    change_timezone: 'Changer le fuseau horaire',
    save_language_settings: 'Sauvegarder les paramètres de langue',
    vehicle_list_options: 'Liste des véhicules',
    select_view: 'Sélectionner la vue',
    select_fields_placeholder: 'Sélectionner les champs à afficher',
    selected_field: 'Champ sélectionné',
    selected_fields: 'Champs sélectionnés',
    list: 'Liste',
    table: 'Tableau',
    view_options: 'Options de vue',
    fluid_table: 'Largeur dynamique',
    fluid_table_descr: 'Fluidifier la largeur du tableau en fonction des champs sélectionnés',
    vehicle_fields_list:
        'Quelles informations souhaitez-vous voir apparaître pour chaque véhicule? (Max 3)',
    vehicle_fields_list_warning:
        "Attention : le champ ne sera pas affiché s'il n'est pas disponible pour le véhicule.",
    vehicle_identification:
        'Comment voulez-vous que le véhicule soit identifié dans la liste principale?',
    apply_to_marker: "Ce paramètre s'applique également aux véhicules présents sur la carte",
    element_icon_section: '',
    element_title_section: '',
    element_fields_list_section: '',
    element_icon_description: '',
    element_icon: '',
    element_icon_color: '',
    options_map: 'Options carte',
    vehicles_clustering: 'Groupe de véhicules',
    vehicles_clustering_warning:
        'La mise à jour de ce paramètre nécessite le rechargement de la page.',
    options_marker: 'Options de marqueur',
    size: 'Taille',
    font_size: 'Taille de texte',
    font_color: 'Couleur de texte',
    background_color: 'Couleur de fond',
    opacity: 'Opacité',
    color: 'Couleur',
    small: 'Petit',
    medium: 'Moyen',
    large: 'Grand',
    additional_info: 'Informations supplémentaires',
    analysis_options: 'Analyse des itinéraires',
    options_chart: 'Options graphique',
    options_timeline: 'Options timeline',
    hide_timeline: 'Cacher timeline',
    bind_colors: 'Associer les couleurs',

    // Others
    map: 'Carte',
    silica: 'Silica',
    google: 'Google',
    google_satellite: 'Satellite Google',
    sandbox: 'Sandbox',
    silkysand: 'Silkysand',
    gravelpit: 'Gravelpit',
    open_street_maps: 'Open Street Maps',
    arcgis_satellite: 'Satellite Arcgis',
    traffic: 'Trafic',
    traffic_anomalies: 'Accidents et anomalies',
    heavy_vehicles_ban: 'Interdictions de véhicules lourds',
    driver: 'Conducteur',
    no_driver: 'Sans conducteur',
    drivers: 'Conducteurs',
    driver_card: 'Carte conducteur',
    name: 'Nom',
    surname: 'Prénom',
    driver_activity: 'Activité conducteur',
    driver_status: 'Statut conducteur',
    driver_statuses: 'Statuts conducteur',
    activity_type: "Type d'activité",
    activity: 'Activité',
    no_activity: 'Aucune activité',
    driver_at_rest: 'Conducteur en repos',
    driver_available: 'Conducteur disponible',
    driver_at_work: 'Conducteur au travail',
    driver_driving: 'Conducteur en conduite',
    inactive_vehicle: 'Véhicule inactif',
    last_driver_detected: 'Dernier conducteur détecté',
    rest: 'Repos',
    work: 'Travail',
    driving: 'Conduite',
    availability: 'Disponibilité',
    codriving: 'Co-conduite',
    codriver: 'Co-conducteur',
    break: 'Pause de session',
    daily_rest: 'Repos quotidien',
    violation: 'Violation',
    to_begin_within: 'A commencer dans',
    to_end_within: 'A terminer dans',
    after_total_time_travel: 'Après un voyage total de',
    after_total_distance_travel: 'Après un voyage total de',
    residual_commitment_type: "Type d'engagement restant",
    residual_commitment: 'Engagement restant',
    commitment_hours: "Heures d'engagement",
    commitment_hours_description:
        "Trouver tous les véhicules dont les conducteurs ont un engagement résiduel plus élevé qu'indiqué",
    driver_plafond: 'Plafond conducteur',
    residual_driving: 'Conduite restant',
    residual_bonuses: 'Bonus restant',
    consider_bonuses: 'Le temps est calculé en tenant compte des bonus résiduel',
    current_activity: 'Activité actuelle',
    next_activity: 'Prochaine activité',
    view_plafond_detail: 'Voir les détails du plafond',
    card: 'Carte',
    driver_change: 'Changement de conducteur',
    shared_links: 'Lien partagé',
    gf: 'POI détecté',
    analyze: 'Analyser',
    plan: 'Planifier',
    vehicle: 'Véhicule',
    vehicles: 'Véhicules',
    vehicle_info: 'Informations sur le véhicule',
    vehicle_type: 'Type de véhicule',
    vehicle_body: 'Corps véhicule',
    truck_status: 'Statut du véhicule',
    brand: 'Marque',
    model: 'Modèle',
    no_card: 'Carte non inséré',
    no_card_driving: 'Carte non inséré en conduite',
    speed: 'Vitesse',
    tacho_speed: 'Vitesse (tachygraphe)',
    vehicle_hooked: 'Véhicule attaché',
    truck_hooked_text: 'Ce véhicule est attaché au suivant remorque: ',
    trailer_hooked_text: 'Ce remorque est attaché au suivant véhicule: ',
    no_driver_rfid_info:
        "La carte du conducteur n'est pas enregistrée dans la liste des conducteurs",
    gps_speed: 'Vitesse (GPS)',
    level: 'Niveau',
    battery: 'Batterie',
    low_battery_level: 'Niveau de batterie faible',
    fuel: 'Carburant',
    fuel_level: 'Niveau de carburant',
    low_fuel_level: 'Niveau de carburant faible',
    consumption: 'Consommation',
    consumptions: 'Consommation',
    fuel_consumption: 'Consommation de carburant',
    fuel_avg_consumption: 'Consommation quotidienne moyenne de carburant',
    altitude: 'Altitude',
    weight: 'Poids',
    lastpoint_weight: 'Poids (dernier point)',
    vehicle_weight: 'Poids (t)',
    vehicle_length: 'Longueur (m)',
    vehicle_width: 'Largeur (m)',
    vehicle_height: 'Hauteur (m)',
    odometer: 'Odomètre',
    odometer_tacho: 'Odomètre (tachygraphe)',
    odometer_gps: 'Odomètre (GPS)',
    gps_odometer: 'Odomètre (GPS)',
    last_point: 'Dernier point',
    moving: 'En mouvement',
    stopped: 'Arrêté',
    engine_on: 'Moteur en marche',
    ignition: 'Tableau de bord',
    engine_off: 'Moteur éteint',
    engine_on_off: 'Allumage du moteur',
    ignition_on: 'Tableau activé',
    ignition_off: 'Tableau éteint',
    device: 'Dispositif',
    plate: 'Plaque',
    chassis: 'Châssis',
    status: 'Statut',
    custom_id: 'Nom personnalisé',
    heading: 'Direction',
    rpm_engine: 'Régime moteur',
    pto_active: 'PTO actif',
    pto_state: 'État PTO',
    device_timestamp: 'Timestamp',
    info: 'Info',
    solar_day_report: 'Rapport de jour solaire',
    telemetry: 'Télémétrie',
    parameters: 'Paramètres',
    engine_coolant_temp: 'Température du moteur',
    engine_rpm: 'Régime moteur',
    dallas_temp_1: 'Température 1',
    dallas_temp_2: 'Température 2',
    dallas_temp_id_1: 'ID Température 1',
    dallas_temp_id_2: 'ID Température 2',
    digital_input_1: 'Entrée digitale 1',
    digital_input_2: 'Entrée digitale 2',
    digital_input_3: 'Entrée digitale 3',
    digital_input_4: 'Entrée digitale 4',
    digital_input_5: 'Entrée digitale 5',
    battery_level: 'Niveau de batterie',
    adblue_liters: 'Litres Adblue',
    adblue_level: 'Niveau Adblue',
    gnss_status: 'Statut GNSS',
    gnss_status_values: [
        'Off',
        'On, sans GPS',
        'On, sans satellites',
        'On, avec satellites',
        'On, en sleep mode',
        'Mode de protection'
    ],
    gnss_off: 'GNSS off',
    gnss_on_fix: 'GNSS on, avec satellites',
    gnss_on_without_fix: 'GNSS on, sans satellites',
    gnss_on_sleep: 'GNSS on, en sleep mode',
    gnss_on_protection: 'GNSS on, protection mode',
    gsm_signal: 'Signal GSM',
    tacho_source: 'Source du tachygraphe',
    external_voltage: 'Alimentation',
    tacho_overspeed: 'Tachygraphe de survitesse',
    no_points_title: 'Dernière activité depuis plus de 24 heures',
    no_points: "Ce véhicule n'a pas envoyé de points depuis plus de 24 heures",
    clutch: 'Embrayage',
    clutch_switch: "Interrupteur d'embrayage",
    brake_pedal: 'Pédale de frein',
    accelerator_pedal: 'Pédale d’accélérateur',
    pedal_released: 'Pédale relâchée',
    pedal_pressed: 'Pédale enfoncée',
    data_mode: 'Mode de données',
    home_stop: 'Home stop',
    home_moving: 'Home moving',
    roaming_stop: 'Roaming stop',
    roaming_moving: 'Roaming moving',
    unknown_stop: 'Unknown stop',
    unknown_moving: 'Unknown moving',
    engine_coolant_temperature: 'Température du liquide de refroidissement',
    engine_temperature: 'Température du moteur',
    cruise_control_active: 'Cruise control actif',
    adblue_off: 'Adblue off',
    adblue_red: 'Adblue red',
    adblue_yellow: 'Adblue yellow',
    adblue_info: 'Adblue info',
    charger_notconnected: 'Chargeur non connecté',
    charger_connected: 'Chargeur connecté',
    tacho_unknown: 'Inconnu',
    tacho_kline: 'K-Line',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: "Le véhicule auquel vous essayez d'accéder n'est pas disponible",
    vehicle_with_lock_commands: 'Véhicule avec commandes de verrouillage',
    lock: 'Verrouiller',
    unlock: 'Déverrouiller',
    send: 'Envoyer',
    command_sent: 'Commande envoyée',
    turn_on: 'Allumer',
    turned_on: 'Allumé',
    turn_off: 'Éteindre',
    turned_off: 'Éteint',
    confirm_send_command: 'Confirmez-vous que vous voulez envoyer la commande ?',
    temperature: {
        title: 'Sondes de température',
        probe: ['Sonde 1', 'Sonde 2', 'Sonde 3', 'Sonde 4', 'Sonde 5', 'Sonde 6']
    },
    no_power_supply: "Pas d'alimentation",
    no_gps: 'Pas de GPS',
    tacho_clock_from_updated_error: "Erreur de synchronisation de l'horloge du tachygraphe",
    tacho_clock_from_device_error:
        "Erreur de synchronisation entre le tachygraphe et l'unité de contrôle",
    device_timestamp_error: "Erreur de date de l'unité de contrôle",
    select_color_mode: 'Sélectionner le mode de couleur',
    light_mode: 'Mode clair',
    dark_mode: 'Mode sombre',
    color_blind_mode: 'Mode daltonien',
    color_blind_dark_mode: 'Mode sombre daltonien',
    authorized: "Autorisé",
    logged_out: "Déconnecté",
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Véhicules en évidence',
            search_trucks_title: 'Rechercher des véhicules',
            search_trucks_tags_title: 'Tag associées',
            search_trucks_status_title: 'Activité du véhicule',
            search_trucks_driver_status_title: 'Activité conducteur',
            search_near_trucks_title: 'Rechercher des véhicules à proximité'
        },
        detailspanel: {
            bind_vehicle: 'Associer véhicule',
            add_new_tag: 'Ajouter comme un nouveau tag',
            select_new_tag: 'Sélectionner ou rechercher un tag',
            binded_tags: 'Tag associé',
            binded_tag: 'Tags associés'
        },
        analyzer: {
            vehicle_activity_analisys: "Analyse de l'activité des véhicules",
            timeline_separator: 'Regroupement par',
            loading_error: ''
        },
        search_resources: {
            title: 'Rechercher des ressources',
            description:
                'Trouvez les stations-service, les parkings et les zones accessibles les plus proches de votre véhicule.'
        }
    },
    analisys: {
        title: 'Analyse des itinéraires',
        select_telemetries: "Sélectionner la télémétrie à afficher dans l'analyse de l'itinéraire",
        select_telemetries_description:
            'Il est possible de sélectionner un maximum de 4 télémétries à afficher',
        chart_groups: {
            movement_data: 'Données sur les mouvements',
            engine_data: 'Données du moteur',
            vehicle_data: 'Données du véhicule',
            sensors_data: 'Données des capteurs',
            driver_data: 'Données sur le conducteur',
            fuel_data: 'Données sur les carburants'
        },
        no_data: 'Aucune donnée disponible',
        no_data_description:
            "Il n'y a aucune donnée d'itinéraire à afficher pour ce véhicule ou pour la période sélectionnée",
        no_data_contact_support: "Contactez le service client pour plus d'informations"
    },
    planner: {
        user_config: 'Configurations utilisateur',
        no_user_config: "Il n'y a aucune configuration utilisateur à afficher",
        freight_access: 'Accès aux chargements de lots',
        vehicle_actual_position: 'Position du véhicule actuel',
        plan_route: "Planifier l'itinéraire",
        select_on_map: 'Sélectionner un point sur la carte',
        select_on_map_option: 'Sélectionner sur la carte',
        fill_route_info: "Remplissez les informations de l'itinéraire",
        fill_resources: 'Sélectionner le point à ajouter comme arrêt',
        fill_waypoints: 'Sélectionner les étapes',
        load_breaks: 'Charger les pauses',
        load_alternative_routes: '',
        savedroutes: {
            my_routes: 'Mes itinérarires',
            saved_routes: 'Itinéraires enregistrés',
            no_saved_routes: "Vous n'avez pas encore enregistré aucune itinéraire",
            success_saved_route: 'Votre chemin a été sauvé avec succès',
            error_saved_route: "Un erreur s'est produite pendant l'enregistrement de l'itinéraire",
            success_deleted_route: 'Votre itinéraire a été éliminé avec succès',
            error_deleted_route: "Un erreur s'est produite pendant l'élimination de l'itinéraire"
        },
        resources: {
            area_search: 'Rechercher dans le rayon de',
            station_services: 'Inclus ces services',
            parking_services: 'Services de stationnement',
            select_rating: 'Sélectionner la notation',
            select_security: 'Sélectionner la sécurité',
            empty_search: "La recherche n'a trouvé aucun élément à montrer",
            delivery_nation: 'Pays de livraison',
            fai_featured: 'Conventionné Lumesia',
            self_service: 'Self Service',
            served: 'Servi',
            total_parking_spots: 'Nombre total de siéges',
            rating: 'Versement',
            reviews: 'Commentaires',
            security: 'Sécurité',
            price_per_night: 'Prix par nuit'
        },
        emissions_labels: 'Émissions',
        emissions: {
            fuelconsumption: 'Consommation de carburant',
            hydrocarbons: 'Hydrocarbures',
            methane: 'Méthane',
            hydrocarbonsexceptmethane: 'Hydrocarbures sauf méthane',
            carbonmonoxide: 'Monoxyde de carbone',
            carbondioxide: 'Gaz carbonique',
            fossilcarbondioxide: 'Dioxyde de carbone fossile',
            sulphurdioxide: 'Dioxyde de soufre',
            nitrogenoxides: "Oxydes d'azote",
            nitrogendioxide: "Dioxyde d'azote",
            nitrousoxide: "Protoxyde d'azote",
            ammonia: 'Ammoniac',
            benzene: 'Benzène',
            particles: 'Particules',
            numberofparticles: 'Nombre de particules'
        },
        plafond_configuration: {
            title: 'Configuration plafond',
            driving_time_regulation: 'Temps de conduite',
            last_time_the_driver_worked: 'Dernière activité du conducteur',
            eu_driving_time_regulation_for_single_day: 'Seule journée',
            eu_driving_time_regulation_for_single_long_day: 'Seule journée (avec des bonus)',
            eu_driving_time_regulation_for_multiple_day: 'Plus de jours',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Plus de jours (avec équipage)',
            accumulated_driving_time_since_lastbreak: 'Heures de conduite depuis la dernière pause',
            accumulated_driving_time_since_lastdailyrest:
                'Heures de conduite depuis le dernier repos quotidien',
            accumulated_travel_time_since_lastdailyrest:
                'Heures de voyage depuis le dernier repos quotidien'
        },
        set_route: "Configurer l'itinéraire",
        select_profile: 'Choisir un profil',
        select_co2_class: "Sélectionner la classe d'émission",
        class: 'Classe',
        billed_roads: 'Routes à péage',
        waypointevent: 'ètape',
        waypoints_label: 'Étapes',
        add_waypoint: 'Ajouter étape',
        delete_waypoint: 'Supprimer étape',
        select_waypoint: "Sélectionner l'étape",
        select_resource: 'Sélectionner le point à ajouter comme arrêt',
        last_waypoint: 'Dernière étape',
        waypoints_check: 'Il y a des étapes à terminer',
        waypoints_required: 'Au moins 2 étapes sont nécessaires',
        countryevent: 'état',
        tollevent: 'Poste de péage',
        event: 'Arrêt',
        alpega: 'Alpega',
        alpega_login: 'Login Alpega',
        alpega_searches: 'Recherches Alpega',
        timocom: 'Timocom',
        pickup: 'Retrait',
        delivery: 'Livraison',
        extimated_cost: 'Coût estimé',
        calculate_route: "Calculer l'itinéraire",
        calculate_isocrona: "Calculer l'isochrone",
        complete_step_2: "Terminez l'étape 2",
        reset_route: 'Nouveau itinéraire',
        route_error: "Un erreur s'est produite pendant le calcul de l'itinéraire",
        edit_route: 'Éditer itinéraire',
        save_route: 'Enregistrer le itinéraire',
        delete_route: "Supprimer l'itinéraire",
        route_name: "Nom de l'itinéraire",
        override_route: "Écraser l'itinéraire",
        save_as_new_route: 'Sauver un nouveau itinéraire',
        calculate_route_for: "Calculer l'itinéraire pour",
        ptvexport: 'Exporter en PTV Navigator',
        isocrona: 'Zones accessibles',
        reset_isocrona: 'Réinitialisation zones accessibles',
        search_location: 'Chercher localité',
        search_station: 'Trouver distributeur',
        search_parking: 'Trouver parking',
        search_poi: 'Cherchez POI',
        include_plafond: "Inclure le plafond du conducteur dans le calcul de l'itinéraire",
        include_plafond_near_vehicles:
            "Trouvez des véhicules avec des chauffeurs avec suffisamment d'efforts restants pour se rendre à l'endroit que vous recherchez",
        violated_plafond: "L'itinéraire sélectionné dépasse le plafond du conducteur"
    },
    sharedlinks: {
        back_to_links: 'Retour aux links',
        create_link: 'Créer des links',
        update_link: 'Mettre à jour le link',
        edit_link: 'Modifier le link',
        update_link_expire_date: "Mettre à jour la date d'expiration",
        delete_link: 'Supprimer le link',
        delete_link_confirm: 'Supprimer le link sélectionné?',
        all_your_shared_links: 'Tous vos links partagés',
        no_shared_links: "Vous n'avez pas encore partagé de véhicule",
        vehicles_link: 'Links pour les véhicules',
        select_share_vehicles: 'Sélectionner des véhicules à partager',
        select_start_expire_date: "Sélectionner la date de démarrage et d'expiration",
        created_link_error: 'Erreur pendant la création du link',
        created_link_success: 'Link créé avec succès',
        updated_link_error: 'Erreur lors de la mise à jour du link',
        updated_link_success: 'Link mis à jour réussi',
        service_description: 'Partager ce link pour montrer le suivi des véhicules sélectionnés',
        link_preview: 'Aperçu du link',
        share_single_vehicle: 'Partagez la position de ce véhicule',
        track_vehicle_position: 'Tracer la position des véhicules sélectionnés',
        start_date_warning: 'La date de début ne peut pas être modifiée',
        start_date_condition:
            'Si vous souhaitez modifier la date de début, vous devez créer un nouveau link',
        expire_date_description:
            "Indiquer une date d'expiration, au-delà de laquelle ce link ne sera plus disponible",
        start_expire_date_description:
            "Indiquer une date initiale, à partir de laquelle ce link sera disponible, et une date d'expiration, au-delà de laquelle ce link ne sera plus disponible"
    },
    viewsharedlink: {
        invalid_date_range:
            "L'intervalle de dates que vous avez sélectionnées n'est pas valable pour ce link",
        too_early_link: "Le link auquel vous essayez d'accéder n'est pas encore disponible",
        too_late_link: "Le link auquel vous essayez d'accéder n'existe plus",
        show_route: "Voir l'itinéraire"
    },
    tags: {
        manage_tags: 'Gérer les tags',
        add_tag: 'Ajouter un tag',
        add_tag_placeholder: 'Ajouter un nouveau tag',
        add_tag_error: "Erreur lors de l'ajout du tag",
        add_tag_success: 'Tag ajouté avec succès',
        delete_tag: 'Supprimer le tag',
        delete_tag_confirm: 'Supprimer le tag sélectionné?',
        delete_tag_error: 'Erreur lors de la suppression du tag',
        delete_tag_success: 'Tag supprimé avec succès',
        update_tag: 'Mettre à jour le tag',
        update_tag_error: 'Erreur lors de la mise à jour du tag',
        update_tag_success: 'Tag mis à jour avec succès',
        all_your_tags: 'Tous vos tags',
        no_tags: "Vous n'avez pas encore de tags",
        tag_name: 'Nom du tag'
    },
    manage_poi: {
        title: 'Gérer les POI',
        your_pois: 'Vos POI',
        add_poi: 'Ajouter un POI',
        no_pois: "Vous n'avez pas encore de POI",
        fields: {
            name: 'Nom',
            type: 'Type',
            color: 'Couleur',
            note: 'Note',
            address: 'Adresse',
            latitude: 'Latitude',
            longitude: 'Longitude',
            radius: 'Rayon',
            radius_description:
                'Pour activer le geofencing, définissez une valeur supérieure à zéro.'
        },
        types_poi: {
            all: 'Tous',
            headquarter: 'Siège',
            truck_washes: 'Nettoyage de camion',
            blacklist: 'Liste noire',
            end_of_the_line: 'Fin de ligne',
            customers: 'Clients',
            stops: 'Stops',
            suppliers: 'Fournisseurs',
            interports: 'Interports',
            markets: 'Marchés',
            large_square: 'Grand carré',
            poi_distributors: 'POI Distributeur',
            poi_workshops: 'POI Atelier',
            harbors: 'Ports',
            restaurants: 'Restaurants',
            stations: 'Stations'
        },
        messages: {
            add_poi_error: 'Erreur lors de la création du POI',
            add_poi_success: 'POI créé avec succès',
            update_poi_error: 'Erreur lors de la mise à jour du POI',
            update_poi_success: 'POI mis à jour avec succès',
            delete_poi_error: 'Erreur lors de la suppression du POI',
            delete_poi_success: 'POI supprimé avec succès'
        }
    },
    vehicles_cross: {
        title: 'Passages des véhicules',
        fields: {
            address: 'Adresse',
            radius: 'Rayon',
            time_interval: 'Intervalle de temps',
            time_interval_description: "L'intervalle de temps maximum est de 24 heures"
        },
        messages: {
            max_time_interval: "L'intervalle de temps maximum est de 24 heures",
            error_searching: 'Erreur lors de la recherche des passages des véhicules',
            searching: 'Recherche...',
            long_loading: 'La recherche peut prendre un certain temps, veuillez patienter',
            no_vehicles_cross: 'Aucun véhicule trouvé'
        }
    },
    plafonds: {
        session: 'Pause de session',
        break: 'Pause',
        drive_occurring: 'Une Période de Conduite est en cours:',
        drive_max_label: 'Temps de conduite maximum',
        drive_max_value: '4h 30m',
        drive_done: 'Conduite effectuée',
        session_title: 'Informations sur la Pause Fractionnée',
        session_tooltip: "Il est possible d'effectuer une Pause Fractionnée",
        session_done: "Une pause d'au moins 15 minutes a été détectée.",
        session_info: "Première partie d'une Pause Fractionnée déjà effectuée",
        session_descr:
            "Nous rappelons que selon les réglementations en vigueur, il est possible de faire une pause de 15'+30' ou de 15'+15'+15' (en fonction du type de transport effectué).",
        session_choice: 'Il est possible de faire au moins <b>30m</b> dans les prochaines',
        brake_start: 'Début de la pause',
        brake_done: 'Pause effectuée',
        daily: 'Quotidienne',
        weekly: 'Hebdomadaire',
        driver_in_drive: 'Vous conduisez depuis',
        driver_in_rest: 'Repos résiduel',
        driver_in_work: 'Travail',
        driver_in_available: 'Disponibilité',
        driver_in_availability: 'Disponibilité',
        current_activity: 'Activité actuelle',
        mini_plafond: 'Mini-Plafond',
        last_update: 'Dernière mise à jour',
        panel_title: 'Panneau Plafond',
        residual_commitment: '',
        daily_driving: 'Conduite quotidienne',
        weekly_guide: '',
        biweekly_guide: 'Guide bihebdomadaire',
        last_rest: 'Dernier repos hebdomadaire pris',
        minimal_duration_next: 'Durée minimale suivante',
        top_start_until: 'Pour commencer par',
        reduced_weekly_rest: '',
        time_to_compensate: 'Temps pour compenser',
        time_to_compensate_until: 'Pour être compensé par',
        footer_message: '* le temps est calculé en considérant les bonus résiduels',
        driver_in_block: '',
        résidu_commitment: 'Engagement résiduel',
        week_guide: 'Guide hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Erreur 403',
            description:
                "Cher client, nous vous informons que votre compte utilisateur n'est pas configuré pour accéder à ce service. Pour recevoir des informations sur ce service",
            retry_login: "Réessayer l'accès",
            contact_us: 'Contactez-nous'
        }
    },
    ptv: {
        maxpermittedweight: 'Poids lourd',
        minpermittedweight: 'Poids minimum',
        zonename: 'Zone',
        unauthorizedtype: 'Code non autorisé',
        maxemissionstandardeu: "Classe d'émission",
        fueltype: 'Type de carburant',
        loadtype: 'Type de transport',
        opening: 'Monter',
        maxheight: 'hauteur maximum',
        maxweight: 'Poids lourd',
        maxwidth: 'Largeur maximale',
        maxlength: 'Longueur maximale',
        absolutespeed: 'Vitesse',
        hazardoustowaters:
            "Interdiction des véhicules transportant des marchandises dangereuses pour l'eau",
        hazardousgoods: 'Interdiction des véhicules transportant des marchandises dangereuses',
        combustibles: 'Interdiction des véhicules transportant du carburant',
        maxaxleload: 'Poids maximum sur les essieux',
        totalpermittedweight: 'Poids lourd',
        tunnelrestriction: 'Code de restriction de tunnelage',
        kingpintorearaxle: "Distance du pivot d'attelage à l'essieu",
        numberofaxles: "Nombre d'axes",
        length: 'Tronçon',
        delay: 'Retard',
        message: {
            text: 'Message'
        }
    },
    options_interface: 'Interface des options'
}
