<template>
    <div
        @click="$emit('click', truck)"
        @contextmenu.prevent="$emit('contextmenu', truck)"
        :class="{
            hidden: !this.visible,
            'on-focus': truck.on_focus || truck.on_activity,
            collapsed: collapsed
        }"
    >
        <slot></slot>

        <div v-if="collapsed" class="">
            <div
                class="truck-header--collapsed"
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    min-height: 65px;
                "
                :id="truck.plate"
            >
                <div
                    class="circle-icon"
                    :style="{
                        'background-color': iconBackgroundColor,
                        'background-image': 'none',
                        position: 'relative'
                    }"
                >
                    <!-- ICON ELEMENT (WITH CONFIG) -->
                    <truck-icon
                        style="padding: 5px; color: #fff"
                        :category="truck.category"
                        v-if="
                            !config.iconType ||
                            (config.iconType && config.iconType.value == 'truck')
                        "
                    />
                    <i class="driver-activity-icon" :class="driverActivityIcon" v-else></i>
                </div>
                <span class="plate-collapsed">{{ identification | truncate(7) }}</span>

                <div
                    class="pin-icon"
                    :class="truck.pinned ? 'pinned' : ''"
                    @click.stop="$emit('pinTruck', truck)"
                >
                    <i class="fa-solid fa-thumbtack"></i>
                </div>
            </div>

            <b-popover
                :target="truck.plate"
                triggers="hover"
                placement="left"
                customClass="truck-popover"
                container="main-panel"
            >
                <ul class="list-inline d-flex justify-content-between mb-0">
                    <li class="list-inline-item">
                        <i
                            v-if="truck.lastpoint.rfid"
                            :style="truck.lastpoint.rfid.report_id == 'authorized' ? 'color: var(--rest-color)' : 'color: var(--driving-color)'"
                            class="fa-solid fa-id-badge"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__(truck.lastpoint.rfid.report_id)"
                        ></i>
                        <i
                            v-else-if="truck.lastpoint.activity === 0"
                            style="color: var(--rest-color)"
                            class="fa-solid fa-bed"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('driver_at_rest')"
                        ></i>
                        <i
                            v-else-if="truck.lastpoint.activity == 1"
                            style="color: var(--availability-color)"
                            class="fak fa-availability"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('driver_available')"
                        ></i>
                        <i
                            v-else-if="truck.lastpoint.activity == 2"
                            style="color: var(--work-color)"
                            class="fak fa-work"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('driver_at_work')"
                        ></i>
                        <i
                            v-else-if="truck.lastpoint.activity == 3"
                            style="color: var(--driving-color)"
                            class="fa-solid fa-steering-wheel"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('driver_driving')"
                        ></i>
                        <i
                            class="fa-solid fa-truck gray-color"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('inactive_vehicle')"
                        ></i>
                    </li>
                    <li class="list-inline-item">
                        <i
                            :class="{
                                'fa-solid fa-user-group': true,
                                'gray-color': !truck.lastpoint.codriver,
                                'green-color': truck.lastpoint.codriver
                            }"
                        ></i>
                    </li>
                    <li class="list-inline-item">
                        <div
                            v-if="vehicleAlertsList.length > 1"
                            :id="`truck_list_items_${truck.id}`"
                        >
                            <i class="fa-solid fa-triangle-exclamation red-color"></i>
                            {{ vehicleAlertsList.length }}
                        </div>

                        <div v-if="vehicleAlertsList.length == 1">
                            <i
                                :class="vehicleAlertsList[0].icon + ' red-color'"
                                v-b-tooltip.left.hover
                                :container="truck.id"
                                :title="__(vehicleAlertsList[0].problem)"
                            ></i>
                        </div>

                        <i
                            v-if="vehicleAlertsList.length == 0"
                            class="fa-solid fa-triangle-exclamation gray-color"
                            v-b-tooltip.hover
                            :container="truck.id"
                            :title="__('no_warning')"
                        ></i>

                        <b-tooltip
                            v-if="vehicleAlertsList.length > 1"
                            class="tooltip-alerts"
                            :target="`truck_list_items_${truck.id}`"
                            triggers="hover"
                            placement="left"
                            v-b-tooltip.hover
                        >
                            <div v-for="alert in vehicleAlertsList" :key="alert.id">
                                <i :class="alert.icon"></i> {{ __(alert.problem) }}
                            </div>
                        </b-tooltip>
                    </li>
                </ul>
            </b-popover>
        </div>

        <div v-if="!collapsed" class="main-cnt">
            <div
                class="conatiner section-veichle"
                :class="$mq !== 'xs' && $mq !== 'sm' ? 'col-10' : 'col-12'"
            >
                <div class="row">
                    <div class="col-2" align="center">
                        <div
                            class="circle-icon"
                            :style="{
                                'background-color': iconBackgroundColor,
                                'background-image': 'none',
                                position: 'relative'
                            }"
                        >
                            <!-- ICON ELEMENT (WITH CONFIG) -->
                            <truck-icon
                                style="padding: 5px; color: #fff"
                                :category="truck.category"
                                v-if="
                                    !config.iconType ||
                                    (config.iconType && config.iconType.value == 'truck')
                                "
                            />
                            <i class="driver-activity-icon" :class="driverActivityIcon" v-else></i>

                            <div
                                class="with-commands"
                                v-if="lock_command"
                                v-b-tooltip.right.hover
                                :title="__('vehicle_with_lock_commands')"
                            >
                                <i class="fa-solid fa-lock fa-2xs"></i>
                            </div>
                        </div>
                        <div
                            class="chain-icon"
                            v-if="is_truck_hooked(truck.plate)"
                            :class="truck.pinned ? 'hooked' : ''"
                            v-b-tooltip.hover.html
                            :title="__('vehicle_hooked')"
                            @click.stop="
                                $emit('detailTruckHooked', get_vehicle_hooked(truck.plate))
                            "
                        >
                            <i class="fa-regular fa-link-horizontal"></i>
                        </div>
                        <div
                            class="pin-icon"
                            :class="truck.pinned ? 'pinned' : ''"
                            @click.stop="$emit('pinTruck', truck)"
                        >
                            <i class="fa-solid fa-thumbtack"></i>
                        </div>
                    </div>

                    <div class="col-10 info-detail" v-if="!hideInfo">
                        <div class="conatiner">
                            <div class="row">
                                <div class="col">
                                    <span
                                        class="plate-full"
                                        :class="[
                                            config?.vehicleIdentification,
                                            identification.length > 10 ? 'long-text' : ''
                                        ]"
                                        >{{ identification }}</span
                                    >
                                </div>
                                <div class="col">
                                    <ul class="list-inline d-flex justify-content-between">
                                        <li class="list-inline-item">
                                            <i
                                                v-if="truck.lastpoint.rfid"
                                                :style="truck.lastpoint.rfid.report_id == 'authorized' ? 'color: var(--rest-color)' : 'color: var(--driving-color)'"
                                                class="fa-solid fa-id-badge"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__(truck.lastpoint.rfid.report_id)"
                                            ></i>
                                            <i
                                                v-else-if="truck.lastpoint.activity === 0"
                                                style="color: var(--rest-color)"
                                                class="fa-solid fa-bed"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('driver_at_rest')"
                                            ></i>
                                            <i
                                                v-else-if="truck.lastpoint.activity == 1"
                                                style="color: var(--availability-color)"
                                                class="fak fa-availability"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('driver_available')"
                                            ></i>
                                            <i
                                                v-else-if="truck.lastpoint.activity == 2"
                                                style="color: var(--work-color)"
                                                class="fak fa-work"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('driver_at_work')"
                                            ></i>
                                            <i
                                                v-else-if="truck.lastpoint.activity == 3"
                                                style="color: var(--driving-color)"
                                                class="fa-solid fa-steering-wheel"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('driver_driving')"
                                            ></i>
                                            <i
                                                v-else
                                                class="fa-solid fa-truck gray-color"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('inactive_vehicle')"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <i
                                                :class="{
                                                    'fa-solid fa-user-group': true,
                                                    'gray-color': !truck.lastpoint.codriver,
                                                    'green-color': truck.lastpoint.codriver
                                                }"
                                            ></i>
                                        </li>
                                        <li class="list-inline-item">
                                            <div
                                                v-if="vehicleAlertsList.length > 1"
                                                :id="`truck_list_items_${truck.id}`"
                                            >
                                                <i
                                                    class="fa-solid fa-triangle-exclamation red-color"
                                                ></i>
                                                {{ vehicleAlertsList.length }}
                                            </div>

                                            <div v-if="vehicleAlertsList.length == 1">
                                                <i
                                                    :class="
                                                        vehicleAlertsList[0].icon + ' red-color'
                                                    "
                                                    v-b-tooltip.left.hover
                                                    :container="truck.id"
                                                    :title="__(vehicleAlertsList[0].problem)"
                                                ></i>
                                            </div>

                                            <i
                                                v-if="vehicleAlertsList.length == 0"
                                                class="fa-solid fa-triangle-exclamation gray-color"
                                                v-b-tooltip.hover
                                                :container="truck.id"
                                                :title="__('no_warning')"
                                            ></i>

                                            <b-tooltip
                                                v-if="vehicleAlertsList.length > 1"
                                                class="tooltip-alerts"
                                                :target="`truck_list_items_${truck.id}`"
                                                triggers="hover"
                                                placement="left"
                                                v-b-tooltip.hover
                                            >
                                                <div
                                                    v-for="alert in vehicleAlertsList"
                                                    :key="alert.id"
                                                >
                                                    <i :class="alert.icon"></i>
                                                    {{ __(alert.problem) }}
                                                </div>
                                            </b-tooltip>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                v-if="
                                    (config?.vehicleIdentification == 'customId' &&
                                        truck.details.custom_id) ||
                                    config?.vehicleIdentification == 'driver' ||
                                    (!config?.vehicleIdentification && truck.details.custom_id)
                                "
                                class="row"
                            >
                                <small class="text-muted my-1 text-dark-blue"
                                    ><i class="fa-solid fa-tag"></i> {{ truck.plate }}</small
                                >
                            </div>
                            <div
                                class="row"
                                v-if="
                                    (config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('address')) ||
                                    !config?.truckListItemFields
                                "
                            >
                                <span class="localizzazione-f reverse-geocoded">
                                    <reverse-geocoding
                                        :latLon="[truck.lastpoint.lat, truck.lastpoint.lng]"
                                        :truncated="70"
                                    />
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex flex-row justify-content-start mx-auto mt-25">
                                <!-- SPEED -->
                                <div
                                    class="pr-4 text-center"
                                    v-if="
                                        (config?.truckListItemFields &&
                                            config?.truckListItemFields.includes('speed')) ||
                                        !config?.truckListItemFields
                                    "
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover.html
                                        :title="
                                            truck.lastpoint.tacho_speed &&
                                            config?.truckListItemFields &&
                                            !config?.truckListItemFields.includes('tacho_speed')
                                                ? __('speed') +
                                                  ' ' +
                                                  truck.lastpoint.speed +
                                                  ' km/h <br> ' +
                                                  __('tacho_speed') +
                                                  ' ' +
                                                  truck.lastpoint.tacho_speed +
                                                  ' km/h'
                                                : __('speed') + ' ' + truckSpeed + ' km/h'
                                        "
                                        ><i class="fa-solid fa-gauge"></i>
                                        {{ truckSpeed }} km/h</span
                                    >
                                </div>

                                <!-- TACHO SPEED -->
                                <div
                                    v-if="
                                        config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('tacho_speed') &&
                                        truck.lastpoint.tacho_speed
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __('tacho_speed') +
                                            ' ' +
                                            truck.lastpoint.tacho_speed +
                                            ' km/h'
                                        "
                                        ><i class="fa-solid fa-gauge"></i>
                                        {{ truck.lastpoint.tacho_speed }} km/h</span
                                    >
                                </div>

                                <!-- HEADING -->
                                <div
                                    v-if="
                                        config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('heading') &&
                                        truck.lastpoint.heading
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="__('heading') + ' ' + truck.lastpoint.heading + '°'"
                                        ><i
                                            class="fa-solid fa-arrow-up"
                                            :style="`transform: rotate(${truck.lastpoint.heading}deg)`"
                                        ></i>
                                        {{ truck.lastpoint.heading }}°</span
                                    >
                                </div>

                                <!-- FUEL LEVEL -->
                                <div
                                    v-if="
                                        ((config?.truckListItemFields &&
                                            (config?.truckListItemFields.includes('fuel_level') ||
                                                config?.truckListItemFields.includes(
                                                    'fuel_battery_level'
                                                ))) ||
                                            !config?.truckListItemFields) &&
                                        truck.lastpoint.fuel_level
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="__('fuel') + ' ' + truck.lastpoint.fuel_level + '%'"
                                        ><i class="fas fa-gas-pump"></i>
                                        {{ truck.lastpoint.fuel_level }}%</span
                                    >
                                </div>

                                <!-- BATTERY LEVEL -->
                                <div
                                    v-if="
                                        ((config?.truckListItemFields &&
                                            (config?.truckListItemFields.includes(
                                                'battery_level'
                                            ) ||
                                                config?.truckListItemFields.includes(
                                                    'fuel_battery_level'
                                                ))) ||
                                            !config?.truckListItemFields) &&
                                        truck.lastpoint?.telemetry?.battery_level &&
                                        !truck.lastpoint.fuel_level
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __('battery') +
                                            ' ' +
                                            (truck.lastpoint?.telemetry?.battery_level || 0) +
                                            '%'
                                        "
                                        ><i class="fas fa-battery"></i>
                                        {{ truck.lastpoint?.telemetry?.battery_level || 0 }}%</span
                                    >
                                </div>

                                <!-- ALTITUDE -->
                                <div
                                    v-if="
                                        ((config?.truckListItemFields &&
                                            config?.truckListItemFields.includes('altitude')) ||
                                            !config?.truckListItemFields) &&
                                        truck.lastpoint.altitude !== null
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __('altitude') + ' ' + truck.lastpoint.altitude + ' m'
                                        "
                                        ><i class="fas fa-mountain"></i>
                                        {{ truck.lastpoint.altitude }} m</span
                                    >
                                </div>

                                <!-- RPM ENGINE -->
                                <div
                                    v-if="
                                        config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('rpm_engine') &&
                                        truck.lastpoint.rpm_engine
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="__('rpm_engine') + ' ' + truck.lastpoint.rpm_engine"
                                        ><i class="fas fa-tachometer-alt"></i>
                                        {{ truck.lastpoint.rpm_engine }} rpm</span
                                    >
                                </div>

                                <!-- DAILY DISTANCE -->
                                <div
                                    v-if="
                                        (config?.truckListItemFields &&
                                            config?.truckListItemFields?.includes(
                                                'daily_distance'
                                            ) &&
                                            (averageData?.distance?.toFixed(0) ||
                                                averageData?.gps_distance?.toFixed(0))) ||
                                        (config?.truckListItemFields &&
                                            config?.truckListItemFields?.includes(
                                                'daily_distance'
                                            ) &&
                                            truck?.lastpoint?.daily_distance)
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __('daily_distance') +
                                            ' ' +
                                            (averageData?.distance?.toFixed(0) ||
                                                averageData?.gps_distance?.toFixed(0))
                                        "
                                        ><i class="fa-solid fa-route"></i>
                                        {{
                                            averageData?.distance?.toFixed(0) ||
                                            averageData?.gps_distance?.toFixed(0) ||
                                            truck?.lastpoint?.daily_distance
                                        }}
                                        Km</span
                                    >
                                </div>

                                <!-- GNSS STATUS -->
                                <div
                                    v-if="
                                        config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('gnss_status') &&
                                        truck.lastpoint?.telemetry?.gnss_status
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __(
                                                'gnss_status_values.' +
                                                    truck.lastpoint?.telemetry?.gnss_status
                                            )
                                        "
                                        ><i class="fa-solid fa-satellite-dish"></i>
                                        {{
                                            parseGNSSStatus(truck.lastpoint?.telemetry?.gnss_status)
                                        }}</span
                                    >
                                </div>

                                <!-- GSM SIGNAL -->
                                <div
                                    v-if="
                                        config?.truckListItemFields &&
                                        config?.truckListItemFields.includes('gsm_signal') &&
                                        truck.lastpoint?.telemetry?.gsm_signal
                                    "
                                    class="pl-4 pr-4 text-center left-bar"
                                >
                                    <span
                                        class="localizzazione-f"
                                        v-b-tooltip.hover
                                        :title="
                                            __('gsm_signal') +
                                            ' ' +
                                            truck.lastpoint?.telemetry?.gsm_signal
                                        "
                                        ><i
                                            class="fa-solid"
                                            :class="
                                                'fa-' +
                                                parseGSMSignal(
                                                    truck.lastpoint?.telemetry?.gsm_signal
                                                ).icon
                                            "
                                        ></i>
                                        {{
                                            parseGSMSignal(truck.lastpoint?.telemetry?.gsm_signal)
                                                .value
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2 action-menu" v-if="$mq !== 'xs' && $mq !== 'sm'">
                <div
                    class="circle-status btn-plananal"
                    @click.stop="$emit('analyze', truck)"
                    v-b-tooltip.left.hover
                    :title="__('analyze')"
                >
                    <i class="fa-solid fa-chart-mixed"></i>
                </div>
                <div
                    v-if="$can('use', 'planner')"
                    class="circle-status btn-plananal"
                    @click.stop="$emit('plan', truck)"
                    v-b-tooltip.left.hover
                    :title="__('plan')"
                >
                    <i class="fa-solid fa-route"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRefs } from 'vue'
import { useVehicleStatus } from '@/composables/useVehicleStatus'
import { useVehicleAlerts } from '@/composables/useVehicleAlerts'
import { useVehicleIdentification } from '@/composables/useVehicleIdentification'
import { useVehicleParameters } from '@/composables/useVehicleParameters'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'TruckListItem',
    props: {
        truck: {
            type: Object,
            required: true
        },
        driver: {
            type: Object,
            required: false
        },
        visible: {
            type: Boolean,
            default: true
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { truck } = toRefs(props)
        const { vehicle_status, vehicle_color } = useVehicleStatus(truck)
        const { alerts } = useVehicleAlerts(truck)
        const { identification } = useVehicleIdentification(truck)
        const { commands, lock_command } = useVehicleParameters(truck)

        return {
            vehicle_status,
            vehicle_color,
            alerts,
            identification,
            commands,
            lock_command
        }
    },
    data() {
        return {
            hideInfo: false,
            averageData: null
        }
    },
    created() {
        this.getAvarageData()
    },
    watch: {
        collapsed(val) {
            var _this = this

            setTimeout(() => {
                _this.hideInfo = val
            }, 500)
        },
        'config.truckListItemFields'() {
            this.getAvarageData()
        },
        truck: {
            handler: function () {
                this.getAvarageData()
            },
            deep: true
        }
    },
    computed: {
        vehicleAlertsList() {
            return Object.values(this.alerts) || []
        },
        ...mapState({
            config: (state) => state.geoFleet.config,
            truck_hooked_list: (state) => state.geoFleet.truck_hooked_list
        }),
        ...mapGetters({
            is_truck_hooked: 'isTruckHooked',
            get_vehicle_hooked: 'getVehicleHooked'
        }),
        truckSpeed() {
            if (
                this.config?.truckListItemFields &&
                this.config?.truckListItemFields.includes('tacho_speed')
            )
                return this.truck.lastpoint.speed
            else return this.truck.lastpoint.tacho_speed || this.truck.lastpoint.speed
        },
        driverActivityIcon() {
            if (this.truck?.lastpoint?.activity == 0) return 'fa-solid fa-bed text-white'
            if (this.truck?.lastpoint?.activity == 1) return 'fak fa-availability text-white'
            if (this.truck?.lastpoint?.activity == 2) return 'fak fa-work text-white'
            if (this.truck?.lastpoint?.activity == 3) return 'fa-solid fa-steering-wheel text-white'
            return 'fa-solid fa-user-large-slash text-white'
        },
        iconBackgroundColor() {
            if (
                !this.config.iconColor ||
                (this.config.iconColor && this.config.iconColor.value == 'truck_status')
            )
                return `var(${this.vehicle_color})`
            else if (this.config.iconColor && this.config.iconColor.value == 'driver_activity') {
                if (this.truck?.lastpoint?.activity == 0) return 'var(--rest-color)'
                if (this.truck?.lastpoint?.activity == 1) return 'var(--availability-color)'
                if (this.truck?.lastpoint?.activity == 2) return 'var(--work-color)'
                if (this.truck?.lastpoint?.activity == 3) return 'var(--driving-color)'
                return 'var(--default-color)'
            } else if (this.config.iconColor && this.config.iconColor.value == 'driver_status') {
                if (this.truck?.lastpoint?.driver?.state?.id) {
                    let id = this.truck?.lastpoint?.driver?.state?.id
                    return this.config.driversStatusColors[id]
                }
                return 'var(--default-color)'
            } else return 'var(--main-text-color)'
        },
        lockCommands() {
            if (!this.truck?.parameters[4] && !this.truck?.parameters[5]) return false

            return (
                (this.truck.parameters[4] && this.truck.parameters[4].valore == '1') ||
                (this.truck.parameters[5] && this.truck.parameters[5].valore == '1')
            )
        }
    },
    methods: {
        async setReversedLocation() {
            let address = await this.$reverseGeocoding({
                lat: this.truck.lastpoint.lat,
                lng: this.truck.lastpoint.lng,
                raw: true
            })

            address = address.reverse().join(', ')

            this.reversed_location = address.length > 75 ? address.slice(0, 70) + '...' : address
        },
        async getAvarageData() {
            if (this.config && this.config?.truckListItemFields?.includes('daily_distance')) {
                try {
                    const response = await this.$fetch(
                        this.$env.GOLIA_API + '/geofleet/trucks/' + this.truck.id + '/average'
                    )

                    if (!response.ok) throw Error(response.statusText)

                    let avarageDataResponse = await response.json()

                    this.averageData = avarageDataResponse.data
                } catch (error) {
                    console.log(error)
                }
            }
        },
        parseGNSSStatus(status) {
            switch (status) {
                case 0:
                    return status + ' OFF'
                case 1:
                    return status + ' ON No-GPS'
                case 2:
                    return status + ' ON No-Sat'
                case 3:
                    return status + ' ON'
                case 4:
                    return status + ' ON Sleep'
                default:
                    return status
            }
        },
        parseGSMSignal(value) {
            let values = {
                0: 'signal-slash',
                1: 'signal-weak',
                2: 'signal-fair',
                3: 'signal-good',
                4: 'signal-strong',
                5: 'signal'
            }

            return {
                value: value,
                icon: values[value]
            }
        }
    }
}
</script>

<style>
.tooltip-inner {
    font-size: 80%;
    text-align: left;
}

@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>

<style scoped lang="scss">
.tooltip.b-tooltip.bs-tooltip-top {
    margin-right: 50px;
    font-size: 80%;
}
.main-cnt {
    position: relative;
    margin: 8px;
    display: flex;
}
.hidden {
    display: none !important;
}
.section-veichle {
    margin-bottom: 0px;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 4px;
}
.pin-icon {
    background-color: var(--dark-blue);
    color: #fff;
    background-image: none;
    width: 25px;
    height: 25px;
    margin-top: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: -10px;

    &:hover {
        background-color: var(--dark-blue--hover);
    }
    &.pinned {
        background-color: var(--success);
        display: flex;

        &:hover i {
            transform: translateY(-2px);
            transition: transform 0.2s ease-in-out;
        }
    }
    i {
        font-size: 12px;
        transform: translateY(0px);
        transition: transform 0.2s ease-in-out;
    }
}

.chain-icon {
    background-color: #51b5c9; //#51b5c9
    color: #fff;
    background-image: none;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: darken(#51b5c9, 10%);
    }
    i {
        font-size: 10px;
    }
}

.truck-header--collapsed .pin-icon {
    top: -10px;
    left: 0px;
}
.icon-geofleet {
    font-size: 40px;
    color: #ffffff;
}
.plate-full {
    color: var(--main-text-color);
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    clear: both;
    float: left;

    &.driver,
    &.long-text {
        font-size: 14px;
    }
}
.icon-status-f {
    float: left;
}
.localizzazione-f {
    color: var(--main-text-color);
    font-size: 0.6rem;
    font-family: 'Stolzl-Medium';

    &.reverse-geocoded {
        font-size: 11px;
        font-family: 'Stolzl-Regular';
        min-height: 33px;
        max-height: 33px;
        overflow: hidden;
    }

    i {
        color: var(--main-text-color);
    }
}
.right-bar {
    border-right: 1px solid #d9dfe4;
}
.left-bar {
    border-left: 1px solid #d9dfe4;
}
.gray-color {
    color: #d0d0d0;
}
.green-color {
    color: #30c57c;
}
.red-color {
    color: #f74f68;
}
small {
    font-size: 0.75em;
}
.on-focus {
    background-color: var(--main-focus);
    border-radius: 25px;
}

.collapsed {
    display: flex;
    justify-content: center;
}
.plate-collapsed {
    color: var(--main-text-color);
    font-size: 0.8rem;
    font-weight: 500;

    display: flex;
    justify-content: center;
}
@media (hover: hover) and (pointer: fine) {
    .main-cnt:hover .pin-icon,
    .truck-header--collapsed:hover .pin-icon {
        display: flex;
    }
    .main-cnt:hover .circle-status {
        opacity: 1;
    }
    .main-cnt:hover .gray-color {
        color: #9d9d9d;
    }
    .btn-plananal:hover {
        box-shadow: inset 0 0 0 3.5em #5b6f83;
        color: #fff;
    }
}
.action-menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
}
.action-menu .circle-status {
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.2;
}
.btn-plananal {
    position: relative;
    cursor: pointer;
    border: 2px solid var(--main-text-color);
    border-radius: 25px;
    height: 2em;
    width: 2em;
    padding: 0;
    overflow: hidden;
    color: var(--main-text-color);
    /* transition: color 0.3s 0.1s ease-out; */
    text-align: center;
    line-height: 195%;

    font-size: 14px;
}
.btn-plananal::before {
    position: absolute;
    top: 2em;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: '';
    border-radius: 50%;
    display: block;
    width: 5.6em;
    height: 5.6em;
    line-height: 5.6em;
    left: -1.4em;
    text-align: center;
    transition: box-shadow 0.5s ease-out;
    z-index: -1;
}
.pl-4 {
    padding-left: 0.46rem;
}
.pr-4 {
    padding-right: 0.46rem;
}
.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
.truck-popover {
    left: -30px !important;
    border: 1px solid #ddd;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
